(function($, Modernizr) {

  function getAnimationEndEvent () {
    var map = {
        'WebkitAnimation' : 'webkitAnimationEnd',
        'MozAnimation'    : 'animationend',
        'OAnimation'      : 'oAnimationEnd',
        'msAnimation'     : 'MSAnimationEnd',
        'animation'       : 'animationend'
      };
    return map[Modernizr.prefixed('animation')];
  }

  function bindDropdown() {

    var $menu = $('.main-nav .menu-menu-container');
    if($menu.hasClass('dropdown')) {
      return;
    }

    $menu
      .addClass('dropdown')
      .removeClass('xsdropdown');

    $menu.find('.glyphicon').remove();

    $menu.find('> ul > li')
      .on('mouseenter.dropdown', function() {
  			$(this)
  				.addClass('active')
  				.find('ul')
          .first()
          .velocity('finish')
          .velocity('fadeIn', { duration : 500 });
  		})
      .on('mouseleave.dropdown', function() {
  			$(this)
  				.removeClass('active')
  				.find('ul')
          .first()
          .velocity('finish')
          .velocity('fadeOut', { duration : 500 });
  		})
  		.find('ul')
  		.hide();
  }

  function bindXsDropdown() {

    var $menu = $('.main-nav .menu-menu-container');
    if($menu.hasClass('xsdropdown')) {
      return;
    }

    $menu
      .removeClass('dropdown')
      .addClass('xsdropdown');

    $menu.find('> ul > li')
      .off('.dropdown')
      .find('.sub-menu')
      .hide();

    $menu.find('.menu-item-has-children > a')
      .prepend('<button class="btn btn-default glyphicon glyphicon-plus" type="button"></button>');

    $menu.find('> ul .glyphicon')
      .on('click.xsdropdown', function(ev) {
        ev.preventDefault();
        var $this = $(this);
        var isOpen = $this.hasClass('glyphicon-minus');
        $this
          .toggleClass('glyphicon-plus')
          .toggleClass('glyphicon-minus')
          .closest('li')
  				.find('ul')
          .first()
          .velocity('finish')
          .velocity(isOpen ? 'slideUp': 'slideDown', {
            duration : 500,
            complete: function() {
              if(isOpen) {
                return;
              }

              $this
                .closest('li')
        				.find('ul')
                .first()
                .css({
                  opacity: 1
                });
            }
          });


  		});
  }

  $(function() {

    $('.main-nav .menu .children')
      .find('.children')
      .hide();

    if(Modernizr.mq('(min-width: 779px)')) {
      bindDropdown();
    } else {
      bindXsDropdown();
    }

    var $body = $(document.body);

    $body
      .on(getAnimationEndEvent(), function(ev) {
        var name = ev.originalEvent && ev.originalEvent.animationName;

    		if( ! name || name !== 'small' && name !== 'large') {
    			return;
    		}

        if(name === 'small') {
          bindXsDropdown();
        } else {
          bindDropdown();
        }
      });
  });

})(jQuery, Modernizr);
